const debounce = function Debounce(fn, t) {
	let delay = t || 500;
	let timer = null;
	return function() {
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			fn.apply(this, arguments);
		}, delay);
	}
}
export {
	debounce
}