<template>
  <div class="">
    <el-dialog title="登录页面" :visible="centerDialogVisible" width="382px" center @close="close" destroy-on-close>
      <div class="main">
        <div class="mainnav flex flex-middle">
          <div class="mainitem" :class="{ active: activeIndex == index }" @click="activeHandler(index)" v-for="(item, index) in listbtn" :key="index">
            <div class="maintext">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.activeIndex===0" class="qrcode">
        <div class="box" v-if='!url' v-loading="loading" element-loading-text="加载中" element-loading-background="rgba(0, 0, 0, 0)" style="width: 166px;height: 166px;">
        </div>
        <div class="box" v-else>
          <img :src="url" alt="" srcset="" />
        </div>
        <div class="qrword">请使用微信扫一扫</div>
      </div>
      <div v-else class="qrcode">
        <div class="box" v-if='!tiktokLoginUrl' v-loading="loading" element-loading-text="加载中" element-loading-background="rgba(0, 0, 0, 0)" style="width: 166px;height: 166px;">
        </div>
        <div class="box1 flex flex-column" v-else>
          <img src="@/assets/image/douyinlogo.png" alt="" srcset="">
          <a :href="tiktokLoginUrl">去抖音扫码页面</a>
        </div>
        <div class="qrword" style="padding-top:50px;">请点击去抖音扫码页面</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from '@/utils/fangdou.js';
import { getCodeunique, islogin, tiktokUnique, tiktokUser } from '@/api/index';
export default {
  name: 'wxlogin',
  data() {
    return {
      url: '',
      loading: true,
      timer: '',
      activeIndex: null,
      listbtn: ['微信登录', '抖音登录'],
      tiktokLoginUrl: '',
      objsearch:{}
    };
  },
  computed: {
    centerDialogVisible() {
      return this.$store.state.loginDialog;
    },
  },
  watch: {
    '$store.state.loginDialog'(val) {
      if (val) {
        if (this.activeIndex == 0) {
          this.wxLogin();
        } else {
          this.tiktokLogin();
        }
      }
    },
  },
  created() {
    this.activeIndex = 0;
    if(window.location.search!=''){
      window.location.search.slice(1).split('&').forEach((item)=>{
        this.objsearch[item.split('=')[0]] = item.split('=')[1]
      })
    }
    if (this.objsearch.code) {
      this.getTiktokUser();
    }
  },
  methods: {
    activeHandler: debounce(function (index) {
      if (this.activeIndex === index) {
        return;
      }
      this.activeIndex = index;
      if (this.activeIndex === 0) {
        this.wxLogin();
      } else {
        clearInterval(this.timer);
        this.tiktokLogin();
      }
    }),
    close() {
      this.$store.commit('setLoginDialog', false);
      clearInterval(this.timer);
    },
    tiktokLogin() {
      tiktokUnique({
        // redirect_uri: 'https://baidu.com',
        redirect_uri: 'https://bk.fengzhishike.cn/',
      }).then((res) => {
        this.tiktokLoginUrl = res.data;
      });
    },
    async wxLogin() {
      await getCodeunique().then((res) => {
        this.strrand = res.data.strrand;
        this.url = res.data.code_url;
      });
      this.timer = setInterval(() => {
        this.callback();
      }, 2000);
    },
    async callback() {
      await islogin({ strrand: this.strrand }).then((res) => {
        if (res.code == 1) {
          clearInterval(this.timer);
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('avatar_text', res.data.avatar_text);
          localStorage.setItem('type', 0);
          this.$store.commit('setLoginDialog', false);
          this.$router.go(0);
        }
      });
    },
    getTiktokUser() {
      tiktokUser({
        code: this.objsearch.code,
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('avatar_text', res.data.avatar);
          localStorage.setItem('type', 1);
          this.$router.go(0);
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.box {
  box-sizing: border-box;
}

.box > img {
  width: 166px;
  height: 166px;
}
.box1 {
  margin: 0 auto;
}
.box1 > img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  text-align: center;
  margin: 0 auto;
  padding: 20px 0;
}
.box1 a {
  margin: 0 auto;
  color: #2a99d6;
  text-decoration: none;
}
.qrword {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #555555;
  line-height: 20px;
  text-align: center;
}

.main {
  width: 100%;
  margin: 0 auto;
  /* padding: 60px 0; */
}

.qrcode {
  width: 166px;
  height: 166px;
  margin: 40px auto;
}

.mainnav {
  margin: 0 auto;
  width: 242px;
  height: 40px;
  border-radius: 14px;
  border: 1px solid #e3e3e3;
  filter: blur(0px);
  cursor: pointer;
}

.mainitem {
  margin: 0 auto;
  width: 120px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 10px;
}

.maintext {
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}

.active {
  background: linear-gradient(212deg, #54c9ed 0%, #2a99d6 100%);
  color: #ffffff;
}
</style>