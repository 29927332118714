<template>
  <div>
    <div class="header flex">
      <img class="header-logo" :src="logo" alt="" srcset="" @click="onNav('/home')" />
      <div class="nav">
        <div class="nav-box" v-for="item in navList" :key="item.id" :class="{ buttm: activeIndex == item.url }">
          <div class="aboutt" @click="onNav(item.url)">{{ item.title }}</div>
          <div class="foot-box" v-show="item.item.length > 0">
            <p v-for="item2 in item.item" :key="item2.id" @click="onNav(item2.url)">{{ item2.title }}</p>
          </div>
        </div>
      </div>
      <div class="login">
        <div v-if="!isLogin" class="loginbox" @click="loginbtn">请登录</div>
        <div v-else class="loginbox1">
          <img :src="avatarUrl" alt="" srcset="" />
          <div class="loginbox1-content" style="border:1px solid #cccc;border-radius: 5px;">
            <div class="l-c-b" style="padding-bottom:5px;border-bottom:1px solid #bbb" @click="onNav('/askprice')">询价记录</div>
            <!-- <div class="l-c-b" style="padding-bottom:5px;border-bottom:1px solid #bbb">询价记录</div> -->
            <div class="l-c-b" style="padding-top:5px;" @click="tclogin">退出登录</div>
          </div>
        </div>
        <wxlogin ref="login" style="position: absolute; left: 0"></wxlogin>
      </div>
    </div>
  </div>
</template>

<script>
import wxlogin from '@/components/wxlogin.vue';
import { getNav, getCommon } from '@/api/index';

export default {
  data() {
    return {
      navList: [],
      logo: '',
      isLogin: false,
      activeIndex: '',
    };
  },
  components: {
    wxlogin,
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route: {
      handler: function (val, oldVal) {
        // console.log(963,val);
        this.activeIndex = val.path;
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {

    getNavList() {
      getNav().then((res) => {
        this.navList = res.data;
      });
    },
    getLogo() {
      getCommon().then((res) => {
        this.logo = res.data[0].logo;
      });
    },
    onNav(url) {
      // console.log(url);
      this.$router.push({ path: url });
    },
    loginbtn() {
      this.$store.commit('setLoginDialog', true);
    },
    tclogin() {
      this.$router.go(0);
      localStorage.clear();
      // this.$router.push({path:'/home'});
      // this.$router.go(0);

    },
  },
  created() {
    this.getNavList();
    this.getLogo();
    if (localStorage.getItem('token')) {
      this.isLogin = true;
      console.log(localStorage.getItem('avatar_text'));
      this.avatarUrl = localStorage.getItem('avatar_text');
    }
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 1500px;
  height: 74px;
  margin: 0 auto;
  align-items: center;
  
  .header-logo {
    width: 170px;
    height: 36px;
    cursor: pointer;
  }
}

.nav {
  margin-left: 150px;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  line-height: 60px;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  white-space: nowrap;

  .nav-box {
    margin: 0px 30px;
    width: 82px;
    border-bottom: 4px solid#ffffff;
    position: relative;

    &:hover {
      border-bottom: 4px solid#2a99d6;
      .aboutt {
        font-weight: 700;
        font-size: 15px;
        color: #2a99d6;
      }
      .foot-box {
        display: block;
      }
    }
    &.buttm {
      border-bottom: 4px solid#2a99d6;
      color: #2a99d6;
      font-weight: 700;
    }

    .foot-box {
      margin-top: 4px;
      margin-left: -30px;
      position: absolute;
      width: 142px;
      height: 174px;
      background: #ffffff;
      display: none;
      z-index: 9;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #525252;

      p {
        height: 50px;

        &:hover {
          display: block;
          color: #2a99d6;
        }
      }
    }
  }

  .about-box {
    margin: 0px 30px;
    width: 82px;
    border-bottom: 4px solid#ffffff;
    position: relative;

    &.buttm {
      border-bottom: 4px solid#2a99d6;
      color: #2a99d6;
      font-weight: 700;
    }

    &:hover {
      border-bottom: 4px solid#2a99d6;

      .aboutt {
        font-weight: 700;
        font-size: 15px;
        color: #2a99d6;
      }

      .foot-box {
        display: block;
      }
    }

    .foot-box {
      margin-top: 4px;
      margin-left: -30px;
      position: absolute;
      width: 142px;
      height: 174px;
      background: #ffffff;
      display: none;
      z-index: 9;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #525252;

      p {
        height: 50px;

        &:hover {
          display: block;
          color: #2a99d6;
        }
      }
    }
  }
}
.l-c-b{
  transition: all 0.3s;
}
.l-c-b:hover{
  color: #2a99d6;
}

.login {
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  margin-left: 4%;

  .loginbox {
    position: relative;
    padding: 10px 20px;
    background: linear-gradient(212deg, #54c9ed 0%, #2a99d6 100%);
    border-radius: 8px;
  }

  .loginbox1 {
    position: relative;

    img {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      line-height: 60px;
    }

    &:hover .loginbox1-content {
      display: block;
    }

    .loginbox1-content {
      display: none;
      z-index: 1;
      position: absolute;
      width: 70px;
      left: -20px;
      color: #555555;
      text-align: center;
      padding: 20px 10px;
      background-color: #ffffff;
      font-size: 15px;
      z-index: 9;
    }
  }
}
</style>