<template>
  <div class="footer">
    <div class="footer-box">
      <div class="left fl">
        <img :src="footerData.footer_logo" alt="" />
        <div class="address flex flex-middle">
          <i class="icon"></i>{{ footerData.map_dz }}
        </div>
        <div class="phone flex flex-middle"><i class="icon"></i>{{ footerData.tel }}</div>
      </div>
      <div class="link-box fr">
        <div class="link-item">
          <div class="title">快速<span style="color: #2a99d6">导航</span></div>
          <ul class="nav">
            <li v-for="item in navList" :key="item.id" @click="onNav(item.url)">{{ item.title }}</li>
          </ul>
        </div>
        <div class="link-item">
          <div class="title">产品<span style="color: #2a99d6">展示</span></div>
          <ul class="product">
            <li v-for="item in productList" :key="item.id" @click="onCategory(item.id)">{{ item.name }}</li>
          </ul>
        </div>
        <div class="link-item">
          <div class="title">联系<span style="color: #2a99d6">我们</span></div>
          <ul class="about">
            <li style="cursor:auto">热线<span>{{ aboutData.mobile }}</span></li>
            <li style="cursor:auto">传真<span>{{ aboutData.fax }}</span></li>
            <li style="cursor:auto">邮箱<span>{{ aboutData.email }}</span></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright">
      © {{ footerData.map_mc }} All Rights Reserved. <span class="beian" @click="fungo"> 鲁ICP备{{ footerData.beian }}</span>
      网站建设：{{ footerData.copyright }}
    </div>
  </div>
</template>

<script>
import { getCommon } from '@/api/index';

export default {
  data() {
    return {
      footerData: {},
      productList: [],
      aboutData: {},
      navList: [],
    };
  },
  methods: {
    getCommonData() {
      getCommon().then((res) => {
        this.footerData = res.data[0];
        this.navList = res.data[1];
        this.productList = res.data[2];
        this.aboutData = res.data[3];
      });
    },
    onNav(url) {
      if (this.activeIndex == url) return;
      this.$router.push({ path: url });
    },
    onCategory(id) {
      this.$router.push({ path: '/product', query: { categoryId: id } });
    },
    fungo(){
      window.open(this.footerData.link2)
    },
  },
  created() {
    this.getCommonData();
  },
  
};
</script>

<style scoped lang="scss">
.beian:hover{
  color: #2a99d6;
  cursor: pointer;
}
.footer {
  height: 246px;
  background: #030506;
  .footer-box {
    height: 166px;
    width: 1500px;
    margin: 0 auto;
    padding-top: 44px;
    .left {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c8c8c8;
      .address {
        margin: 24px 0 11px;
        .icon {
          display: inline-block;
          width: 22px;
          height: 22px;
          background-image: url(@/assets/image/addressicon.png);
          margin-right: 11px;
        }
      }
      .phone {
        .icon {
          display: inline-block;
          width: 22px;
          height: 22px;
          background-image: url(@/assets/image/phoneicon.png);
          margin-right: 11px;
        }
      }
    }
    .link-box {
      padding-right: 40px;
      .link-item {
        float: left;
        margin-left: 130px;
        .title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          width: 170px;
          padding-bottom: 9px;
          border-bottom: 1px solid #4b4b4b;
          margin-bottom: 11px;
        }
        ul {
          width: 170px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9c9c9c;
          li {
            margin-bottom: 10px;
            cursor: pointer;
          }
        }
        .nav {
          li {
            float: left;
            margin-right: 20px;
          }
        }
        .product {
          li {
            float: left;
            width: 50%;
          }
        }
        .about {
          width: 240px;
          span {
            margin-left: 14px;
          }
        }
      }
    }
  }
  .copyright {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #eeeeee;
    height: 36px;
    background: #464646;
    line-height: 36px;
  }
}
</style>