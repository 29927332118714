<template>
  <div>
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style lang='scss'>
@import "@/assets/style/main.scss";

</style>