import axios from 'axios'


//第二步:创建一个新的axios对象[包含一些其余配置项]
let request = axios.create({
	// baseURL: "http://aromatherapy.fengzhishike.cn",
	baseURL: "https://aromatherapy.fengzhishike.cn",

	timeout: 5000, //超时的时间的设置
	headers: {
		'Content-Type': 'application/json',
	},
});
//请求拦截器
request.interceptors.request.use((config) => {

	const token = localStorage.getItem('token')
	if (token) {
		config.headers.token = `${token}`;
	}
	return config;
},
	(error) => {
		return Promise.reject('111', error);
	}
);

//响应拦截器:use回调  响应成功回调  响应失败的回调
request.interceptors.response.use((res) => {
	//数据响应成功回调 
	return res.data; //简化服务器返回的数据

}, (err) => {
	//存在代理跨域没有设置
	if (err.code == "ERR_NETWORK") {
	}
	//响应失败的回调---终止Promise链即可
	//响应失败的判断可以在这里处理
	return new Promise(() => { }); //终止Promise，返回一个pending类型Promise对象
});

//对外暴露
export default request;